import React, { useState, useEffect } from 'react';
import fb from '../firebase';
import { Line } from 'react-chartjs-2';

function LineChart() {

    const [loading, setLoading] = useState(true);
    const [months, setMonths] = useState([]);
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        const getMonths = [];
        const response = fb.collection('followers').orderBy('amount')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getMonths.push({
                        ...doc.data(),
                    });
                });
                setMonths(getMonths);
                setLoading(false);
            });

            var graphs2 = [];
            var amount2 = [];
            for (let i=0; i<months.length; i++) {
                graphs2.push(months[i].month);
                amount2.push(months[i].amount);
            setGraphData({
                labels: graphs2,
                datasets: [
                    {
                        label: 'Followers growth 2020-21',
                        data: amount2,
                        color: ['rgb(0,255,255)'],
                        borderColor: ['rgba(0,255,255,0.8)'],
                        backgroundColor : ['rgba(255,255,0,0.7'],
                        pointBorderColor: 'rgba(0,255,255,0.8)',
                        pointBackgroundColor : 'rgba(255,255,0,0.7',
                    }
                ],
            })
        }
        return () => response();
    }, [loading]);

    const options = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        size:14,
                        family: 'Quicksand',
                        weight: 700,
                        
                    },
                },
            },
        },
    }

    return <Line data={graphData} options ={options} />
}

export default LineChart;
