import './App.css';
import React, { useState, useEffect, useRef } from "react";
import collabs from './graphics/collabs.png';
import { Link } from 'react-router-dom';
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import LineChart from './objects/LineChart';
import { Helmet } from 'react-helmet';
import afrobeats from './graphics/afrobeats-text.png';


function Home() {

  const [sloading, setSLoading] = useState(true);
  const [cloading, setCLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const getStats = [];
    const response = fb.firestore().collection('stats').orderBy('time', 'desc')
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getStats.push({
            ...doc.data(),
            key: doc.id
          });
        });
        setStats(getStats);
        setSLoading(false);
      });
    return () => response();
  }, [sloading]);

  useEffect(() => {
    const getCounter = [];
    const res = fb.firestore().collection('counters').where('name', '==', 'subcounter')
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getCounter.push({
            ...doc.data(),
          })
        });
        setCounter(getCounter);
        setCLoading(false);
      });
    return () => res();

  }, [cloading]);


  return (
    <div className="App">
      <Helmet>
        <title>Home | Afro Lister</title>
      </Helmet>
      <header className="Main-header">
        <Navbar />
        <div className="Home-header">
          <h2 className="summaryh2">A hotspot for one of the hottest</h2>
          <img className='afrobeats-text' src={afrobeats} />
          <h2 className="summaryh2-second">playlists on Spotify.</h2>
          <div className="Middle-header">
            <div className="P-header">
              <p className="summaryp">
                Afro Lister is the one stop place to view information about one of the top Afrobeats playlists available on Spotify:&nbsp;
                <a href="https://open.spotify.com/playlist/4HXMPRVKOAfzoUwws8fqHW" className="summaryplink">
                  Afrobeats 2024.
                </a>
              </p>
              <p className='summaryp'>
                Got music you think will fit the playlist? Submit your music now to be taken into consideration for a placement:
              </p>
              <div onClick={() => window.location.reload()}><Link to="/submit" className="summaryb">Submit Music</Link></div>
              <p className="summaryp">
              Amplify your music's reach with Mamba Sounds distribution – connecting A&R, artists, and labels through an exclusive network:
              </p>
              <div><a className="mambab" href="https://mambasounds.co.uk/">Mamba Sounds</a></div>
            </div>
          </div>
        </div>
        <div className='stats-collab-header'>
          <div className="stats-header">
            <h3>Playlist Stats</h3>
            <h1>Data refreshed every week.</h1>
            <div className="stats">
              {stats.map((stat) =>
                <div className="statsmainh2">
                  <div className='stat'>
                    <h2 className='stat-name'>Followers</h2>
                    <h2 className="stat-number">{!sloading && stat.followers}</h2>
                  </div>
                  <div className='stat'>
                    <h2 className='stat-name'>Tracks</h2>
                    <h2 className="stat-number">{!sloading && stat.tracks}</h2>
                  </div>
                  <div className='stat'>
                    <h2 className='stat-name'>Submissions</h2>
                    <h2 className="stat-number">{!cloading && counter[0].amount}</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <iframe src="https://open.spotify.com/embed/playlist/4HXMPRVKOAfzoUwws8fqHW" className='spotify-player' frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          </div>
          <div className="Collab-w-text-header">
            <h3>Collaborations include:</h3>
            <div className="Collab-header">
              <img src={collabs}></img>
            </div>
          </div>
        </div>
        <div className='home-spacer' />
      </header>
      {/* <header className="Main-header-second">

        <div className="Secondapp-header">
          <h2 className="secondsummaryh2">Playlist Stats <h1>Data refreshed every 24 hours.</h1></h2>
          <div className="stats">
            {stats.map((stat) =>
              <div className="statsmain">
                <div className="statsmainh2">
                  <h2><h2>Followers:</h2>&nbsp;<h2 className="statsnumbers">{!sloading && stat.followers}</h2></h2>
                  <h2><h2>Tracks:</h2>&nbsp;<h2 className="statsnumbers">{!sloading && stat.tracks}</h2></h2>
                  <h2><h2>Submissions:</h2>&nbsp;<h2 className="statsnumbers">{!cloading && counter[0].amount}</h2></h2>
                </div>
                <div>
                  <iframe src="https://open.spotify.com/embed/playlist/4HXMPRVKOAfzoUwws8fqHW" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
              </div>
            )}
            <h3>Playlist Growth</h3>

          </div>
          <div className="chart">
            <LineChart />
          </div>
        </div>
      </header> */}

      <BottomNavbar />

    </div>
  );
}

export default Home;
