import './App.css';
import React from "react";
import {Route, Link} from 'react-router-dom';
import Home from './Home';
import Submit from './Submit';
import Submitted from './Submitted';
import Lists from './Lists';
import Admin from './Admin';
import About from './About';
import Contact from './Contact';
import Report from './Report';
import Reported from './Reported';
import Submissions from './Submissions';

function App() {
  
  return (
    <div className = "App">
      <Route exact path = "/" component = {Home}/>
      <Route exact path = "/submit" component = {Submit}/>
      <Route exact path = "/submissions" component = {Submissions}/>
      {/* <Route exact path = "/lists" component = {Lists}/> */}
      <Route exact path = "/submitted" component = {Submitted}/>
      <Route exact path = "/exotichaha123" component = {Admin}/>
      <Route exact path = "/about" component = {About}/>
      <Route exact path = "/contact" component = {Contact}/>
      <Route exact path = "/report" component = {Report}/>
      <Route exact path = "/reported" component = {Reported}/>
    </div>
  );
}

export default App;
