import React, { useState, useEffect } from "react";
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import "./App.css";
import { useForm, useFormContext } from 'react-hook-form';
import fb from 'firebase/app';
import 'firebase/auth';        
import 'firebase/storage';     
import 'firebase/database';    
import 'firebase/firestore';   
import 'firebase/messaging';   
import 'firebase/functions';  
import Reported from "./Reported";
import { Helmet } from 'react-helmet';

function Report() {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState(false);
    
    const submitReport = (data) => {

        var d = new Date();
        var n = d.getTime();
        var dd = String(d.getDate()).padStart(2, '0');
        var mm = String(d.getMonth() + 1).padStart(2, '0');
        var yyyy = d.getFullYear();
        d = mm + '/' + dd + '/' + yyyy;
        const response = fb.firestore().collection('reports');
        response.add({
            report: data.report,
            reportdesc: data.reportdesc,
            time: n,
            date: d,
            resolved: 0
        })
        setSubmitted(true);
    }
    
    if (!submitted) {
        return (
            <div className="App">
                <Helmet>
                    <title>Report | Afro Lister</title>
                </Helmet>
                <header className="Submit-header">
                    <Navbar />
                    <header className="Submit-main-header">
                        <h2 className="subheader">Report An Issue</h2>
                        <div className="subp">
                            <p>
                                Use this form to report any issues you may be facing on the website.
                            </p>
                        </div>
                        <form className="sform" onSubmit={handleSubmit(submitReport)}>
                            <select id="report" className = "report" type="text" name="report" {...register('report', { required: true })}>
                            <option value = "" selected="selected">Select Error Type</option>
                            <option value = "error submitting music">Error Submitting Music</option>
                            <option value = "error viewing lists">Error Viewing Lists</option>
                            <option value = "other">Other</option>
                            </select>
                            {errors.report && errors.report.value !== "" && <p>This is required</p>}
                            <textarea name = "reportdesc" type="text" className = "reportdesc" placeholder="Description " {...register('reportdesc', {required : true,},{maxLength: {value: 300, message: "Error description too long. Please reduce the characters"} ,minLength: {value: 10, message:"Error description too short. Please describe it further"}})} />
                            {errors.reportdesc && errors.reportdesc.type === "required" && <p>This is required</p>}
                            {errors.reportdesc && errors.reportdesc.type === "maxLength" && <p>{errors.reportdesc.message}</p>}
                            {errors.reportdesc && errors.reportdesc.type === "minLength" && <p>{errors.reportdesc.message}</p>}
                            <input type="submit" style={{ borderWidth: 0 }} className = "summaryb" />
                        </form>
                    </header>
                    <BottomNavbar />
                </header>
            </div>
        );
    } else {
        return (
            <Reported/>
        )
    }
} 

export default Report;