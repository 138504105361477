import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Submitted() {
    return (
        <div className="App">
            <Helmet>
                <title>Submitted | Afro Lister</title>
            </Helmet>
            <header className="Lists-header">
                <header className="App-header">
                    <h2 className="subheader">Submission Received!</h2>
                    <div className="submittedp">
                        <p>
                            Your submission has now been sent for review. Songs are reviewed within 3 days.
                            Keep an eye out on the submissions page or an email if you went for the option to see if your song has been accepted, or visit <a className="summaryplink" href="https://open.spotify.com/playlist/4HXMPRVKOAfzoUwws8fqHW">the playlist</a>
                            &nbsp;to see if your song has been added!
                        </p>
                    </div>
                    <div onClick = {() => window.location.reload()}><Link to="/"><button style={{borderWidth: 0, borderColor: 'transparent'}} className = "summaryb">Return to Home</button></Link></div>
                </header>
            </header>
        </div>
    )
}

export default Submitted;