import React from 'react';
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import {Link } from 'react-router-dom';
import instagram from "./graphics/new-instagram-logo-white-border-icon-png-large.png"
import linkedin from "./graphics/white-linkedin-icon-transparent-background-7.jpg"
import mamba from './graphics/mamba.png';
import submithub from "./graphics/1456753639.1418_submithub-logo.png"
import ppush from "./graphics/ppush.png"
import email from "./graphics/white-email-icon-25.jpg"
import { Helmet } from 'react-helmet';

function Contact() {
    return (
        <div className = "App">
            <Helmet>
                <title>Contact | Afro Lister</title>
            </Helmet>
            <header className = "Secondmain-header">
                <Navbar/>
                <div className = "Contact-header">
                    <h2 className = "subheader">Contact</h2>
                    <div className = "contactp">
                        <p>
                            Find below different ways to contact me regarding anything to do with the playlist. Please refrain from sending music submissions through email, instead use one of the many platforms available.
                            For any issues with the site, please use the <span onClick = {() => window.location.reload()}><Link to = "/report">report issue </Link></span> form.
                        </p>
                    </div>
                    <div className = "contact">
                        <h2>Instagram: <a className = "summaryplink" href = "https://www.instagram.com/vxpz">@vxpz</a></h2>
                        <a href = "https://www.instagram.com/vxpz"><img src = {instagram}></img></a>
                    </div>
                    <div className = "contact">
                        <h2>Email: <a className = "summaryplink" href = "mailto: ziyadr29@yahoo.com">ziyadr29@yahoo.com</a></h2>
                        <a href = "mailto: ziyadr29@yahoo.com"><img src = {email}></img></a>
                    </div>
                    <div className = "contact">
                        <h2>Mamba Sounds: <a className = "summaryplink" href = "https://mambasounds.co.uk/">Learn More</a></h2>
                        <a href = "https://mambasounds.co.uk/"><img className='mamba' src = {mamba}></img></a>
                    </div>
                    <div className = "contact">
                        <h2>LinkedIn: <a className = "summaryplink" href = "https://www.linkedin.com/in/ziyad-alrasbi/">Ziyad Alrasbi</a></h2>
                        <a href = "https://www.linkedin.com/in/ziyad-alrasbi/"><img src = {linkedin}></img></a>
                    </div>
                    {/* <div className = "contact">
                        <h2>Submithub: <a className = "summaryplink" href = "https://www.submithub.com/playlister/afrobeats?view=about">AFROBEATS</a></h2>
                        <a href = "https://www.submithub.com/playlister/afrobeats?view=about"><img src = {submithub}></img></a>
                    </div> */}
                </div>
            </header>
            <BottomNavbar/>
        </div>
    )
}

export default Contact;