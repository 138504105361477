import React, { useState, useEffect } from "react";
import Spotify from 'spotify-web-api-js';
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import "./App.css";
import { useForm, useFormContext } from 'react-hook-form';
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import { Route, Link } from 'react-router-dom';
import Submitted from './Submitted';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';
import emailjs from '@emailjs/browser';

function Submit() {

    var dateNow = new Date();
    var timeNow = dateNow.getTime();

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState(false);
    const [lists, setLists] = useState([]);
    const [acceptedLists, setAcceptedLists] = useState([]);
    const [sloading, setSLoading] = useState(true);
    const [cloading, setCLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    const [contained, setContained] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    var test = false;

    const EMAIL_PUBLIC_KEY = 'xuPXhgzAuqk9t9vP5';
    const SERVICE_ID = 'service_g0t2ueb';
    const SUBMISSION_EMAIL_TEMPLATE = 'template_x0my4me';

    useEffect(() => {
        const getSongs = [];
        const getAcceptedSongs = [];
        const res = fb.firestore().collection('submissions').orderBy('time', 'desc')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getSongs.push({
                        ...doc.data(),
                    })

                    doc.data().accepted == 1 && getAcceptedSongs.push({
                        ...doc.data(),
                    })
                });
                setLists(getSongs);
                setAcceptedLists(getAcceptedSongs);
                setSLoading(false);
            });
        return () => res();

    }, [sloading]);

    useEffect(() => {
        const getCounter = [];
        const res = fb.firestore().collection('counters').where('name', '==', 'subcounter')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getCounter.push({
                        ...doc.data(),
                    })
                });
                setCounter(getCounter);
                setCLoading(false);
            });
        return () => res();

    }, [cloading]);

    const submitSong = (data) => {
        test = true;
        for (let i = 0; i < lists.length; i++) {
            if (lists[i].link == data.link) {
                test = true;
                setContained(true);
                break;
            } else {
                test = false;
                setContained(false);
            }
        }
        if (test == false) {

            var d = new Date();
            var n = d.getTime();
            var dd = String(d.getDate()).padStart(2, '0');
            var mm = String(d.getMonth() + 1).padStart(2, '0');
            var yyyy = d.getFullYear();
            d = mm + '/' + dd + '/' + yyyy;
            const response = fb.firestore().collection('submissions');
            var c = counter[0].amount;
            c++;
            response.add({
                desc: data.desc,
                link: data.link,
                email: data.email,
                time: n,
                date: d,
                accepted: 0
            })
            fb.firestore().collection('counters').where('name', '==', 'subcounter')
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        fb.firestore().collection('counters').doc(doc.id)
                            .update({
                                amount: c
                            })
                    });
                });
            

            setSubmitted(true);
            // send email

            var templateParams = {
                link: data.link,
                email: data.email != null ? data.email : "No email provided",
                description: data.desc != null ? data.desc : "No description provided",
                date: d,
                counter: c
            };

            emailjs.send(SERVICE_ID, SUBMISSION_EMAIL_TEMPLATE, templateParams, EMAIL_PUBLIC_KEY)
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                }, function (error) {
                    console.log('FAILED...', error);
                });

        }
    }

    const delaySubmission = (data) => {
        setSubmitting(true);
        setTimeout(() => submitSong(data), 2000)
    }

    if (!submitted) {
        return (
            <div className="App">
                <Helmet>
                    <title>Submit | Afro Lister</title>
                </Helmet>
                <header className="Submit-header">
                    <Navbar />
                    <header className="Submit-main-header">
                        <h2 className="subheader">Submit Your Music</h2>
                        <div className="subp">
                            <p className="submitp">
                                Submit songs that you think will fit the playlist. If you would like to receive feedback on whether the song you submitted has
                                been accepted, you can also provide an email.  Head to the submissions page to view recent and accepted submissions.
                            </p>
                        </div>
                        {/* <div className="subp">
                            <p className="holidayp">
                                I am currently on holiday, so submissions will be closed from now till the 23rd. After that, submissions will be open again so set your dates!
                            </p>
                        </div> */}
                        <form className="sform" onSubmit={handleSubmit(delaySubmission)}>
                            <input id="link" className="link" type="text" placeholder="Song Link" name="link" {...register('link', { required: true, pattern: { value: /^https?:\/\/open.spotify.com\/track\/[-a-zA-Z0-9@:%&._\+~#?!\/=]{1,256}$/, message: "Invalid input: links must follow the structure of https://open.spotify.com/track/. . ." } })} />
                            {errors.link && errors.link.type === "required" && <p>This is required</p>}
                            {errors.link && errors.link.type === "pattern" && <p>{errors.link.message}</p>}
                            <input id="email" className="link" type="text" placeholder="Email (optional)" name="email" {...register('email', { pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Invalid input: please enter a valid email" } })} />

                            {errors.email && errors.email.type === "pattern" && <p>{errors.email.message}</p>}
                            <textarea type="text" className="desc" placeholder="Description (optional)" {...register('desc', { maxLength: { value: 300, message: "Description too long. Please reduce the characters" } })} />
                            {errors.desc && errors.desc.type === "maxLength" && <p>{errors.desc.message}</p>}
                            {(!sloading || !cloading) &&
                                <input type="submit" style={{ borderColor: 'transparent', visibility: submitting == true ? 'hidden' : 'initial', maxHeight: submitting == true ? 0 : 'auto' }} className="summaryb" />
                            }
                            {(sloading || cloading || submitting == true) &&
                                <div className="loading">
                                    <ReactLoading type='spin' color='#F89B8C' width={'auto'} className="loading" />
                                </div>
                            }
                            {contained &&
                                <p>This song has been submitted before, please try another link and submit again</p>
                            }
                        </form>
                    </header>
                </header>
                <BottomNavbar />
            </div>
        );
    } else {
        return (
            <Submitted />
        )
    }
}

export default Submit;

