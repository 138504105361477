import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Reported() {
    return (
        <div className="App">
            <Helmet>
                <title>Reported | Afro Lister</title>
            </Helmet>
            <header className="Lists-header">
                <header className="App-header">
                    <h2 className="subheader">Issue Report Received!</h2>
                    <div className="submittedp">
                        <p>
                           Your report of an issue has been received and will be reviewed.
                           Reports always take highest priority, so it will be resolved as soon as possible.
                        </p>
                    </div>
                    <div onClick = {() => window.location.reload()}><Link to="/"><button style={{ borderWidth: 0 }} className = "summaryb">Return to Home</button></Link></div>
                </header>
            </header>
        </div>
    )
}

export default Reported;