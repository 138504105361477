import firebase from 'firebase/app';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyAXNjzNjvPAiVlnjYS-ZDY67p_lD--Ey-w",
    authDomain: "afro-lister.firebaseapp.com",
    projectId: "afro-lister",
    storageBucket: "afro-lister.appspot.com",
    messagingSenderId: "172443671668",
    appId: "1:172443671668:web:e4474c135b88a7fc5e8521",
    measurementId: "G-JZEYKS5RNH"
  };

const fireBaseApp = firebase.initializeApp(firebaseConfig);
const fb = firebase.firestore();
fireBaseApp.analytics();

export default fb;