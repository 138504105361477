import './App.css';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import collabs from './graphics/collabs.png';
import { Route, Link } from 'react-router-dom';
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import Spotify from 'spotify-web-api-js';
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import emailjs from '@emailjs/browser';


var tracks = [];
var links = [];
var artists = [];
var covers = [];

function Admin() {

  var d = new Date();
  var n = d.getTime();

  const spotifyWebApi = new Spotify();



  const [links2, setLinks] = useState([]);
  const [lists2, setLists] = useState([]);
  const [artists2, setArtists] = useState([]);
  const [covers2, setCovers] = useState([]);
  const [length, setLength] = useState([]);
  const [sloading, setSLoading] = useState(true);
  const [rloading, setRLoading] = useState(true);
  const [reloading, setRELoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [reports, setReports] = useState([]);
  const [resolved, setResolved] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [declined, setDeclined] = useState([]);

  const SERVICE_ID = 'service_g0t2ueb';
  const ACCEPTED_TEMPLATE_ID = 'template_qmi42ug';
  const DECLINED_TEMPLATE_ID = 'template_t17o8rq';
  const EMAIL_PUBLIC_KEY = 'xuPXhgzAuqk9t9vP5';

  const fetchLists = () => {
    var d = new Date();
    var n = d.getTime();
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = d.getFullYear();
    d = mm + '/' + dd + '/' + yyyy;

    const response = fb.firestore().collection('lists');

    if (tracks.length > 0) {
      response.add({
        songs: tracks,
        links: links,
        covers: covers,
        artists: artists,
        time: n,
        date: d
      })
    }
  }

  useEffect(() => {
    getHashParams();
  }, [])

  const getHashParams = () => {
    var hashParams = {};
    var e, r = /([^&;=]+)=?([^&;]*)/g,
      q = window.location.hash.substring(1);
    while (e = r.exec(q)) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
  }

  const params = getHashParams();

  if (params.access_token) {

    spotifyWebApi.setAccessToken(params.access_token);
  }

  useEffect(() => {
    getMyPlaylistTracks();
  }, [])


  const getMyPlaylistTracks = async () => {
    tracks = [];
    links = [];
    covers = [];
    artists = [];
    var l = 0;
    var conartists = "";
    await spotifyWebApi.getPlaylist('4HXMPRVKOAfzoUwws8fqHW')
      .then((response) => {
        l = response.tracks.total;
      })

    for (let i = 0; i < l; i++) {
      await spotifyWebApi.getPlaylistTracks('4HXMPRVKOAfzoUwws8fqHW')
        .then((response) => {
          conartists = "";
          setLists(tracks.push(response.items[i].track.name));
          setLinks(links.push(response.items[i].track.external_urls.spotify));
          setCovers(covers.push(response.items[i].track.album.images[0].url));
          if (response.items[i].track.artists.length < 2) {
            conartists = response.items[i].track.artists[0].name;
          } else {
            for (let j = 0; j < response.items[i].track.artists.length; j++) {
              conartists += response.items[i].track.artists[j].name + ", ";
            }
          }
          if (conartists.endsWith(", ")) {
            conartists = conartists.substring(0, conartists.length - 2);
          }
          setArtists(artists.push(conartists));
        });
    }

  }



  function getMyPlaylistInfo() {
    const db = fb.firestore().collection('stats');
    var d = new Date();
    var n = d.getTime();
    spotifyWebApi.getPlaylist("4HXMPRVKOAfzoUwws8fqHW")
      .then((response) => {
        db.doc('mXyEdawlI6jGI7pXL0GT').update({
          followers: response.followers.total,
          tracks: response.tracks.total,
          submissions: "3k+",
          time: n
        })
      })
  }

  function getMyFollowers() {
    const db = fb.firestore().collection('followers');
    var d = new Date();
    var n = d.getTime();
    var m = d.toLocaleString('default', { month: 'long' }).substring(0, 3);
    spotifyWebApi.getPlaylist("4HXMPRVKOAfzoUwws8fqHW")
      .then((response) => {
        db.add({
          amount: response.followers.total,
          month: m
        })
      })
  }

  const acceptEmail = (songId, email) => {
    var templateParams = {
      id: songId,
      email: email
    };
    emailjs.send(SERVICE_ID, ACCEPTED_TEMPLATE_ID, templateParams, EMAIL_PUBLIC_KEY)
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function (error) {
        console.log('FAILED...', error);
      });
  }

  function acceptSong(song, songId) {
    fb.firestore().collection('submissions').where('link', '==', song)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().email) {
            acceptEmail(songId, doc.data().email);
          }
          fb.firestore().collection('submissions').doc(doc.id)
            .update({
              accepted: 1
            })
        })
      })
  }


  const declineEmail = (songId, email) => {
    var templateParams = {
      id: songId,
      email: email
    };
    emailjs.send(SERVICE_ID, DECLINED_TEMPLATE_ID, templateParams, EMAIL_PUBLIC_KEY)
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function (error) {
        console.log('FAILED...', error);
      });
  }

  function declineSong(song, songId) {
    fb.firestore().collection('submissions').where('link', '==', song)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().email) {
            declineEmail(songId, doc.data().email);
          }
          console.log(songId);
          fb.firestore().collection('submissions').doc(doc.id)
            .update({
              accepted: 2
            })
        })
      })
  }

  function resolveReport(report) {
    fb.firestore().collection('reports').where('reportdesc', "==", report)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fb.firestore().collection('reports').doc(doc.id)
            .update({
              resolved: 1
            })
        })
        refresh();
      })
  }

  useEffect(() => {
    const getSubmissions = [];
    const response = fb.firestore().collection('submissions').where('accepted', '==', 0).orderBy('time').limit(50)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getSubmissions.push({
            ...doc.data(),
            key: doc.id
          });
        });
        setSubmissions(getSubmissions);
        setSLoading(false);
      });
    return () => response();
  }, [sloading]);

  useEffect(() => {
    const getReports = [];
    const response = fb.firestore().collection('reports').where('resolved', '==', 0)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getReports.push({
            ...doc.data(),
            key: doc.id
          });
        });
        setReports(getReports);
        setRLoading(false);
      });
    return () => response();
  }, [rloading]);

  if (sloading && rloading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    )
  }

  const refresh = () => {
    setReports({});
  }

  function cleanDatabase() {
    fb.firestore().collection('submissions').orderBy('time')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (((n - doc.data().time) / 1000) > 1.814e+6) {
            fb.firestore().collection('submissions').doc(doc.id)
              .delete();
          }
        });
      });
  }

  return (
    <div className="Lists-header">
      <div className="App-header">
        <div>
          <a href="http://localhost:8888/"><button className="submittedb">login to spotify</button></a>
          <button className="submittedb" onClick={() => { getMyPlaylistInfo() }}>add playlist stats</button>
          <button className="submittedb" onClick={() => { getMyFollowers() }}>add chart followers</button>
          <button className="submittedb" onClick={() => { fetchLists() }}>add weekly list</button>
          <button className="submittedb" onClick={() => { cleanDatabase() }}>clean database</button>
        </div>

        <div className="adminsplit">
          <div>
            {submissions.length > 0 ? (
              submissions.map((sub, idx) =>
                sub.accepted === 0 &&
                <div>
                  <h2 className="submitheader"><h3>Submission {idx + 1}</h3></h2>
                  <div className="mainlists" key={idx}>
                    <div className="submitlists">
                      <iframe className="submitplayer" src={sub.link.slice(0, 24) + "/embed" + sub.link.slice(24)} width="100%" height="232" frameBorder="0" allow="encrypted-media" allowtransparency="true"></iframe>
                      <h2 className="subdate">{sub.desc}</h2>
                      <h2 className="subdate">Submitted on: {sub.date}</h2>
                      <h2 className="subdate">Email: {sub.email ? sub.email : "N/A"}</h2>
                      <div>
                        <button className="submittedb" onClick={() => { acceptSong(sub.link, idx + 1) }}>Accept</button>
                        <button className="submittedb" onClick={() => { declineSong(sub.link, idx + 1) }}>Decline</button>
                      </div>
                    </div>
                  </div>

                </div>)) : (
              <h1 className="subp">No submissions</h1>)}
          </div>
          <div>
            {reports.length > 0 ? (
              reports.map((rep, idx) =>
                <div>
                  <div className="subp">
                    <p>Report {idx + 1}</p>
                  </div>
                  <div className="mainlists" key={idx}>
                    <div className="submitlists">
                      <h2 className="admindesc">{rep.report}: {rep.reportdesc}</h2>
                      <h2 className="admindesc">Submitted on: {rep.date}</h2>
                      <div>
                        <button className="submittedb" onClick={() => { resolveReport(rep.reportdesc) }}>Mark Resolved</button>
                      </div>
                    </div>
                  </div>
                </div>)) : (
              <h1 className="subp">No Reports</h1>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin;