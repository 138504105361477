import React, { useState, useEffect } from "react";
import Spotify from 'spotify-web-api-js';
import Navbar from './objects/Navbar';
import BottomNavbar from './objects/BottomNavbar';
import "./App.css";
import { useForm, useFormContext } from 'react-hook-form';
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import Carousel, { consts } from 'react-elastic-carousel';
import arrow from './graphics/arrow.png';
import arrowleft from './graphics/arrowleft.png';
import options from './graphics/options.png';
import arrowdown from './graphics/downarrow.png';
import { Helmet } from 'react-helmet';
import Select, { components } from 'react-select';
import ReactLoading from 'react-loading';

function Submissions() {

    var dateNow = new Date();
    var timeNow = dateNow.getTime();

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
        { width: 1000, itemsToShow: 2 },
        { width: 1650, itemsToShow: 3 }
    ];

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [lists, setLists] = useState([]);
    const [acceptedLists, setAcceptedLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({ value: "recent" });

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <img src={arrowleft} /> : <img src={arrow} />;
        return (
            <div onClick={onClick} className="carousel-arrow" style={{ visibility: isEdge ? "hidden" : "inherit" }} >{pointer}</div>
        )
    }


    useEffect(() => {
        const getSongs = [];
        const getAcceptedSongs = [];
        const res = fb.firestore().collection('submissions').orderBy('time', 'desc')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getSongs.push({
                        ...doc.data(),
                    })

                    doc.data().accepted == 1 && getAcceptedSongs.push({
                        ...doc.data(),
                    })
                });
                setLists(getSongs);
                setAcceptedLists(getAcceptedSongs);
                setLoading(false);
            });
        return () => res();

    }, [loading]);

    function changeFilter() {
        var filterOptions = document.getElementById("filter-options");
        var selectedValue = filterOptions.options[filterOptions.selectedIndex].value;
        setFilter(selectedValue);
    }

    const optionsList = [
        { value: 'recent', label: 'Recent Submissions' },
        { value: 'accepted', label: 'Accepted Submissions' }
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderWidth: "0px",
            borderStyle: "none",
            color: '#212B3A',
            backgroundColor: 'white',
            "&:hover": {
                backgroundColor: "#59A1D6"
            }

        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '10px',
            borderStyle: 'solid',
            borderWidth: "1px",
            backgroundColor: '#212B3A',
            width: 'auto',


        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
            backgroundColor: '#212B3A',
            "&:hover": {
                borderWidth: "1px",
                borderColor: "red"
            }

        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#F57E6B" // Custom colour
        }),
        menuList: (base) => ({
            ...base,
            "::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
                visibility: 'hidden',
                backgroundColor: "black"
            },
        })
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <img style={{ width: "15px", alignSelf: "center", marginBottom: "-2px" }} src={arrowdown} />
            </components.DropdownIndicator>
        );
    };

    return (
        <div className="App">
            <Helmet>
                <title>Submissions | Afro Lister</title>
            </Helmet>
            <header className="Submit-header">
                <Navbar />
                <header className="Submissions-main-header">
                    <h2 className="subheader">Playlist Submissions</h2>
                    <div className="subp">
                        <p className="submitp">
                            Songs submitted for playlist consideration.
                        </p>
                    </div>
                    {/* <form className="filter">
                        <select className="filter-select" name="filter-options" id="filter-options" onChange={changeFilter}>
                            <option value="recent">Recent submissions</option>
                            <option value="accepted">Accepted submissions</option>
                        </select>
                        </form> */}
                    <div className="filter-options">
                        <Select
                            defaultValue={filter.value}
                            placeholder="Recent Submissions"
                            onChange={setFilter}
                            options={optionsList}
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                        />
                    </div>

                    <h2 className="submitheader"><h3 className="subp">{filter.value == "recent" ? "Recent submissions from the last 3 days." : "Accepted submissions from the last 21 days."}</h3></h2>
                    <div className="carousel-header">
                        <div className="filter">
                            <Carousel renderArrow={myArrow} breakPoints={breakPoints}>
                                {filter.value == "recent" ?
                                    (lists.length > 0 && !loading) ? (
                                        lists.map((sub, idx) =>
                                            ((timeNow - sub.time) / 1000) < 259200 &&
                                            <div>

                                                <div className="mainlists" key={idx}>
                                                    <div className="submitlists">
                                                        <iframe className="submitplayer" src={sub.link.slice(0, 24) + "/embed" + sub.link.slice(24)} width="100%" height="232" frameBorder="0" allow="encrypted-media" allowtransparency="true"></iframe>

                                                        {sub.accepted == 0 && <h2 className="subpending"><span className="substatus">Status:</span> Pending</h2>}
                                                        {sub.accepted == 1 && <h2 className="subaccepted"><span className="substatus">Status:</span> Accepted</h2>}
                                                        {sub.accepted == 2 && <h2 className="subdeclined"><span className="substatus">Status:</span> Declined</h2>}
                                                        <h2 className="subdate">Date submitted: {sub.date}</h2>
                                                    </div>
                                                </div>

                                            </div>)
                                    )
                                        : loading ? (
                                            <div className="loading">
                                                <ReactLoading type='spin' color='#F89B8C' width={'auto'} className="loading" />
                                            </div>
                                        ) :
                                            <h2 className="submitheader"><h3 className="subp">No submissions to show for now!</h3></h2>



                                    :
                                    acceptedLists.length > 0 ? (
                                        acceptedLists.map((sub, idx) =>
                                            ((timeNow - sub.time) / 1000) < 1.814e+6 &&
                                            <div>

                                                <div className="mainlists" key={idx}>
                                                    <div className="submitlists">
                                                        <iframe className="submitplayer" src={sub.link.slice(0, 24) + "/embed" + sub.link.slice(24)} width="100%" height="232" frameBorder="0" allow="encrypted-media" allowtransparency="true"></iframe>

                                                        {sub.accepted == 0 && <h2 className="subpending"><span className="substatus">Status:</span> Pending</h2>}
                                                        {sub.accepted == 1 && <h2 className="subaccepted"><span className="substatus">Status:</span> Accepted</h2>}
                                                        {sub.accepted == 2 && <h2 className="subdeclined"><span className="substatus">Status:</span> Declined</h2>}
                                                        <h2 className="subdate">Date submitted: {sub.date}</h2>
                                                    </div>
                                                </div>

                                            </div>)) : (
                                        <h2 className="submitheader"><h3 className="subp">No submissions to show for now!</h3></h2>
                                    )
                                }
                            </Carousel>
                        </div>
                    </div>



                </header>

            </header>
            <BottomNavbar />
        </div>
    )
}

export default Submissions;