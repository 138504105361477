import React from "react";
import { Link } from 'react-router-dom';
import logo from '../graphics/logo.png';

function BottomNavbar() {
    return (
        <nav className="Bottom-header">
            <div onClick={() => window.location.reload()}><Link to="/"><img className="bottom-logo" src={logo} /></Link></div>
            <div className="bottom">
                <div onClick={() => window.location.reload()}><Link to="/about"> <h3 className="bottomh3">About</h3></Link></div>
                <div onClick={() => window.location.reload()}><Link to="/contact"> <h3 className="bottomh3">Contact</h3></Link></div>
                <div onClick={() => window.location.reload()}><Link to="/report"><h3 className="bottomh3">Report Issue</h3></Link></div>
            </div>
        </nav>
    )
}

export default BottomNavbar;