import React from 'react'
import Navbar from './objects/Navbar'
import BottomNavbar from './objects/BottomNavbar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function About() {

    return (
        <div className="App">
            <Helmet>
                <title>About | Afro Lister</title>
            </Helmet>
            <header className="Secondmain-header">
                <Navbar />
                <div className="About-header">
                    <h2 className="subheader">About Afro Lister</h2>
                    <div className="aboutp">
                        <h2>The Platform</h2>
                        <p>
                            Afro Lister is a platform for artists, record labels, and listeners to view all types of information
                            about one of the top Afrobeats playlists on Spotify, <a href="https://open.spotify.com/playlist/4HXMPRVKOAfzoUwws8fqHW" className="summaryplink">
                                Afrobeats 2024.
                            </a>
                        </p>
                        <h2>The Playlist</h2>
                        <p>
                            The playlist is carefully and professionaly curated, updated daily with new music from small or big name
                            artists providing the hottest Afrobeats music around the globe. It's also fully refreshed every weekend
                            to ensure the playlist stays new, and listeners can stay engaged and find new music through it.
                        </p>
                        <h2>Submit Music</h2>
                        <p>
                            The platform allows artists and record labels to easily <span onClick={() => window.location.reload()}><Link to="/submit">submit music </Link></span>
                            in one step for review, giving them a chance to also supply a description with the submission
                            to help understand the song better.
                        </p>
                        {/* <h2>Previous Lists</h2>
                        <p>
                            Because the playlist is refreshed every week, listeners often get in contact to find out the name of a song
                            or artist that was featured in a previous refresh of the playlist, therefore the platform also provides a
                            place for listeners to <span onClick={() => window.location.reload()}><Link to="/lists">view a log of each list.</Link></span>
                        </p> */}
                        <h2>About Me</h2>
                        <p>
                            I'm Ziyad, and I'm the curator behind the playlist. I spend a huge amount of my day listening to music
                            and have a great taste for it so being able to curate a playlist like this has been a great opportunity
                            for me to provide great sounding music for my listeners. I'm also a software engineer, so building
                            this platform was a chance for me to use and showcase my technical skills and build an all-in-one
                            platform for the playlist. To get in touch with me, head over to the <span onClick={() => window.location.reload()}><Link to="/contact">contact page.</Link></span>
                        </p>
                    </div>
                </div>
            </header>
            <BottomNavbar />
        </div>
    )
}


export default About;