import React, { useState, useEffect } from 'react';
import Navbar from './objects/Navbar'
import BottomNavbar from './objects/BottomNavbar'
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';
import downarrow from './graphics/downarrow.png';
import playicon from './graphics/play3.png';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

function Lists() {

  var dateNow = new Date();
  var timeNow = dateNow.getTime();

  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState({});
  const [play, setPlay] = useState({});

  const toggleHide = index => {
    setToggle({ ...toggle[index], [index]: !toggle[index] });
  }

  const togglePlay = index => {
    setPlay({ ...play[index], [index]: !play[index] });
  }

  useEffect(() => {
    const getSongs = [];
    const response = fb.firestore().collection('lists').orderBy('time', 'desc')
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getSongs.push({
            ...doc.data(),
            key: doc.id
          });
        });
        setLists(getSongs);
        setLoading(false);
      });
    return () => response();
  }, [loading]);

  return (
    <div className="App">
      <Helmet>
        <title>Lists | Afro Lister</title>
      </Helmet>
      <header className="Lists-header">
        <Navbar />
        <header className="Lists-main-header">
          <h2 className="subheader">View Previous Lists</h2>
          <div className="subp">
            <p>
              Select any of the lists below to view the playlist of that week.
            </p>
          </div>
          <div className='lists-wrapper'>
            {lists.length > 0 && !loading ? (
              lists.map((song, idx) => (
                ((timeNow - song.time) / 1000) < 2.419e+6 &&
                <div>
                  <div className='single-list-wrapper' key={idx}>
                    <button key={idx} className="viewlistsb" onClick={() => toggleHide(idx)}>
                      <div>
                        <h2>Playlist from week ending </h2>
                        <h3>{song.date}</h3>
                      </div>
                      <img src={downarrow} />
                    </button>
                  </div>
                  {!!toggle[idx] &&
                    <div className="mainlists">
                      {song.songs.map((names, i) =>
                        <div className="lists" key={i}>
                          <iframe className="player" src={song.links[i].slice(0, 24) + "/embed" + song.links[i].slice(24)} width="100%" height="232" frameBorder="0" allow="encrypted-media" allowtransparency="true"></iframe>
                          <a href={song.links[i]}>{names}
                            <br></br>
                            <div className="featuringtext">
                              By {song.artists[i]}
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  }</div>))) : loading ? (
                    <div className="loading">
                      <ReactLoading type='spin' color='#F89B8C' width={'auto'} className="loading" />
                    </div>
                  ) :
              <h2 className="submitheader"><h3 className="subp">No lists to show for now!</h3></h2>
            }
          </div>
        </header>

        <BottomNavbar />
      </header>
    </div>
  );
}

export default Lists;