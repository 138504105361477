import React from "react";
import { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import right from '../graphics/right.png';
import left from '../graphics/left.png';
import logo from '../graphics/logo.png';
import mamba from '../graphics/mamba.png'
import LoadingBar from "react-top-loading-bar";

function Navbar() {

    useEffect(() => {
        const toggleButton = document.getElementsByClassName('toggle-hamburger')[0];
        const navbarLinks = document.getElementsByClassName('navbar-links')[0];
        toggleButton.addEventListener('click', () => {
            navbarLinks.classList.toggle('active')
        })
    }, []);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(40);
        setTimeout(() => { }, 200);
        setProgress(100);
    }, [progress])

    return (
        <nav style={{ zIndex: 9999 }} className="Top-header">
            <LoadingBar color="#ffffff" height={6} progress={progress} />
            <div className="navbar">
                <div>
                    <div className="navbar-logo">
                        <div onClick={() => window.location.reload()}><Link to="/"><img className="logo" src={logo} /></Link></div>
                    </div>
                    <img className="image-navbar" src={left}></img>
                </div>
                <div>
                    <div className="navbar-options">
                        <div><a href="https://mambasounds.co.uk/"><h3 className="mambah3">Mamba <br/> Sounds</h3></a></div>
                        <div onClick={() => window.location.reload()}><Link to="/submit"><h3 className="toph3">Submit</h3></Link></div>
                        <div onClick={() => window.location.reload()}><Link to="/submissions"><h3 className="toph3">Submissions</h3></Link></div>
                        {/* <div onClick={() => window.location.reload()}><Link to="/lists"><h3 className="toph3">Lists</h3></Link></div> */}
                        <div onClick={() => window.location.reload()}><Link to="/about"><h3 className="toph3">About</h3></Link></div>
                        <div onClick={() => window.location.reload()}><Link to="/contact"><h3 className="toph3">Contact</h3></Link></div>
                    </div>
                    <img className="image-navbar" src={right}></img>
                </div>
            </div>
            <div className="mobile-logo">
                <div onClick={() => window.location.reload()}><Link to="/"><img className="logo" src={logo} /></Link></div>
            </div>
            <a href="#" className="toggle-hamburger">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </a>
            <nav className="Toph3-header">
                <div className="navbar-links">
                    <div class="nav-item dropdown">
                        <div><h3 className="toph3">Submit</h3></div>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <div onClick={() => window.location.reload()}><Link to="/submit"><h3 className="droph3">Submit Music</h3></Link></div>
                            <div onClick={() => window.location.reload()}><Link to="/submissions"><h3 className="droph3">Recent Submissions</h3></Link></div>
                        </div>
                    </div>
                    <div><a href="https://mambasounds.co.uk/"><h3 className="mambah3">Mamba Sounds</h3></a></div>
                    <div className="recenth3" onClick={() => window.location.reload()}><Link to="/submit"><h3 className="toph3">Submit</h3></Link></div>
                    <div className="recenth3" onClick={() => window.location.reload()}><Link to="/submissions"><h3 className="toph3">Submissions</h3></Link></div>
                    {/* <div onClick={() => window.location.reload()}><Link to="/lists"><h3 className="toph3">Lists</h3></Link></div> */}
                    <div onClick={() => window.location.reload()}><Link to="/about"><h3 className="toph3">About</h3></Link></div>
                    <div onClick={() => window.location.reload()}><Link to="/contact"><h3 className="toph3">Contact</h3></Link></div>

                </div>
            </nav>


        </nav>

        // <nav className="Top-header">
        /* <div onClick = {() => window.location.reload()}><h3 className="toplogo"><Link to="/">Afro Lister</Link></h3></div> */
        //         <a href = "#" className="toggle-hamburger">
        //             <span className="bar"></span>
        //             <span className="bar"></span>
        //             <span className="bar"></span>
        //         </a>
        //     <nav className = "Toph3-header">

        //         <div className = "navbar-links">

        //         <div class="nav-item dropdown">
        //             <div><h3 className="toph3">Submit</h3></div>
        //             <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        //             <div onClick = {() => window.location.reload()}><Link to="/submit"><h3 className="droph3">Submit Music</h3></Link></div>
        //             <div onClick = {() => window.location.reload()}><Link to="/submissions"><h3 className="droph3">Recent Submissions</h3></Link></div>
        //             </div>
        //         </div>
        //         <div className="recenth3" onClick = {() => window.location.reload()}><Link to="/submit"><h3 className="toph3">Submit</h3></Link></div>
        //         <div className="recenth3" onClick = {() => window.location.reload()}><Link to="/submissions"><h3 className="toph3">Submissions</h3></Link></div>
        //         <div onClick = {() => window.location.reload()}><Link to="/lists"><h3 className="toph3">Lists</h3></Link></div>
        //         <div onClick = {() => window.location.reload()}><Link to="/about"><h3 className="toph3">About</h3></Link></div>
        //         <div onClick = {() => window.location.reload()}><Link to="/contact"><h3 className="toph3">Contact</h3></Link></div>
        //         </div>
        //     </nav>
        // </nav>
    );
}

export default Navbar;